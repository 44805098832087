

























































































































































































































































































































































































































































import { SearchBuilder } from "@/builder";
import { setNumbering } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import { DATE_TIMESTAMP } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import {
  DataListAssetBook,
  DataResponseCalendar,
  DataResponseGetReportAsset,
  DataResponseListSegment,
} from "@/models/interface/assets.interface";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouse,
  DataWarehouseBranch,
  DataWarehouseLocation,
} from "@/models/interface/logistic.interface";
import { assetsServices } from "@/services/assets.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";

type Row = {
  no: number;
  key: number;
  assetCategoryId: string;
  qrCode: string;
} & DataResponseGetReportAsset;

export default Vue.extend({
  name: "ListOfAssets",
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getListCalendar = debounceProcess(this.getListCalendar, 200);
    this.getListPurchaseStatus = debounceProcess(
      this.getListPurchaseStatus,
      200
    );
    this.getListRetiredType = debounceProcess(this.getListRetiredType, 200);
    this.getBranch = debounceProcess(this.getBranch, 200);
    this.getListEquipment = debounceProcess(this.getListEquipment, 200);
    this.getListMerk = debounceProcess(this.getListMerk, 200);
    this.getListType = debounceProcess(this.getListType, 200);

    return {
      params: {} as RequestQueryParamsModel,
      bookId: "" as string,
      loadingSearch: false as boolean,
      loadingListEquipment: false as boolean,
      loadingListMerk: false as boolean,
      loadingListType: false as boolean,
      loadingListAssetBook: false as boolean,
      loadingCalendar: false as boolean,
      loadingDownload: false as boolean,
      loading: false as boolean,
      loadingPrint: false as boolean,
      loadingStatus: false as boolean,
      loadingPurchaseStatus: false as boolean,
      loadingRetiredType: false as boolean,
      loadingBranch: false as boolean,
      loadingLocationWarehouse: false as boolean,
      loadingProductCode: false as boolean,
      state: {
        time: null,
        isOpen: false,
      },
      valueQr: [] as string[],
      dataListLocationWarehouse: [] as DataWarehouse[],
      dataListLocationRack: [] as DataWarehouseLocation[],
      dataListBook: [] as DataListAssetBook[],
      dataBranch: [] as DataWarehouseBranch[],
      dataCalendar: [] as DataResponseCalendar[],
      dataListStatus: [] as ResponseListMaster[],
      dataListPurchaseStatus: [] as ResponseListMaster[],
      dataListRetiredType: [] as ResponseListMaster[],
      dataListType: [] as ResponseListMaster[],
      dataListMerk: [] as DataResponseListSegment[],
      dataListEquipment: [] as DataResponseListSegment[],
      visible: false as boolean,
      visibleQr: false as boolean,
      totalElements: 0 as number,
      limit: 10 as number,
      page: 1 as number,
      form: this.$form.createForm(this, { name: "reportListOfAssets" }),
      formRules: {
        assetNumber: {
          label: "lbl_asset_number",
          name: "assetNumber",
          placeholder: "lbl_asset_number_placeholder",
          decorator: ["assetNumber"],
        },
        equipment: {
          label: "lbl_equipment",
          name: "equipment",
          placeholder: "lbl_equipment_placeholder",
          decorator: ["equipment"],
        },
        merk: {
          label: "lbl_brand",
          name: "merk",
          placeholder: "lbl_brand_placeholder",
          decorator: ["merk"],
        },
        type: {
          label: "SGU/NON SGU/CHILD/RENT",
          name: "type",
          placeholder: "Insert SGU/NON SGU/CHILD/RENT",
          decorator: ["type"],
        },
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_book_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        periode: {
          label: "lbl_acquisition_date",
          name: "periode",
          placeholder: "lbl_period",
          decorator: ["periode"],
        },
        assetYear: {
          label: "lbl_asset_year",
          name: "assetYear",
          placeholder: "lbl_asset_year",
          decorator: ["assetYear"],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        warehouse: {
          label: "lbl_warehouse",
          name: "warehouse",
          placeholder: "lbl_warehouse_placeholder",
          decorator: [
            "warehouse",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rack: {
          label: "lbl_rack",
          name: "rack",
          placeholder: "lbl_rack_placeholder",
          decorator: [
            "rack",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_status_placeholder",
          decorator: [
            "status",
            {
              rules: [{ type: "array" }],
            },
          ],
        },
        purchaseStatus: {
          label: "lbl_purchase_status",
          name: "purchaseStatus",
          placeholder: "lbl_purchase_status_placeholder",
          decorator: ["purchaseStatus"],
        },
        retiredType: {
          label: "lbl_retired_type",
          name: "retiredType",
          placeholder: "lbl_retired_type_placeholder",
          decorator: ["retiredType"],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        poRentToRent: {
          label: "lbl_po_rent_to_rent",
          decorator: [
            "poRentToRent",
            {
              valuePropName: "checked",
            },
          ],
        },
      },
      dataSource: [] as Row[],
      columnsTable: [
        {
          title: this.$t("lbl_number_short"),
          dataIndex: "no",
          key: "no",
          width: 55,
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          // width: 150,
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          key: "equipment",
          // width: 150,
        },
        {
          title: this.$t("lbl_merk"),
          dataIndex: "merk",
          key: "merk",
          width: 75,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          // width: 150,
        },
        {
          title: this.$t("lbl_model"),
          dataIndex: "model",
          key: "model",
          // width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_sn"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          // width: 150,
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "spec",
          key: "spec",
          // width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_cost"),
          dataIndex: "assetCost",
          key: "assetCost",
          // width: 150,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_acquisition_date"),
          dataIndex: "acquisitionDate",
          key: "acquisitionDate",
          // width: 200,
        },
        {
          title: this.$t("lbl_life_months"),
          dataIndex: "lifeMonths",
          key: "lifeMonths",
          // width: 150,
        },
        {
          title: this.$t("lbl_depreciation_per_month"),
          dataIndex: "depreciation",
          key: "depreciation",
          // width: 150,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_accumulated_depre"),
          dataIndex: "accumulateDepre",
          key: "accumulateDepre",
          // width: 220,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: "NBV",
          dataIndex: "nbv",
          key: "nbv",
          // width: 150,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_book_asset"),
          dataIndex: "assetBook",
          key: "assetBook",
          // width: 150,
        },
        {
          title: this.$t("lbl_sgu_non_sgu_child"),
          dataIndex: "segment3",
          key: "segment3",
          // width: 150,
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "status",
          key: "status",
          // width: 200,
        },
        {
          title: this.$t("lbl_rent_status"),
          dataIndex: "rent",
          key: "rent",
          // width: 200,
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          // width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "location",
          key: "location",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_purchase_status"),
          dataIndex: "purchaseStatus",
          key: "purchaseStatus",
          // width: 200,
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          // width: 250,
        },
        {
          title: this.$t("lbl_warehouse"),
          dataIndex: "warehouseName",
          key: "warehouseName",
          // width: 250,
        },

        {
          title: this.$t("lbl_asset_number"),
          dataIndex: "assetNo",
          key: "assetNo",
          // width: 150,
        },
        {
          title: this.$t("lbl_asset_name"),
          dataIndex: "assetName",
          key: "assetName",
          // width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_asset_category"),
          dataIndex: "assetCategoryId",
          key: "assetCategoryId",
          // width: 150,
        },
        {
          title: this.$t("lbl_ytd_depreciation"),
          dataIndex: "ytdDepre",
          key: "ytdDepre",
          // width: 200,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_qr_code"),
          dataIndex: "qrCode",
          key: "qrCode",
          // width: 150,
          scopedSlots: { customRender: "qrCode" },
        },
      ],
    };
  },
  methods: {
    handleChangeLocationBranch(id) {
      this.form.resetFields(["warehouse", "rack"]);
      this.getListWarehouse("", id);
    },
    getListEquipment(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        search: `second~null`,
      };
      if (valueSearch) params.search = `second~null_AND_first~*${valueSearch}*`;
      this.loadingListEquipment = true;
      assetsServices
        .listSegment(params)
        .then(response => {
          this.dataListEquipment = response.data.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.first === value.first)
          );
        })
        .finally(() => (this.loadingListEquipment = false));
    },
    getListMerk(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        search: `second!null`,
      };
      if (valueSearch) params.search = `second!null_AND_first~*${valueSearch}*`;
      this.loadingListMerk = true;
      assetsServices
        .listSegment(params)
        .then(response => {
          this.dataListMerk = response.data.filter(
            (value, index, self) =>
              index === self.findIndex(t => t.first === value.first)
          );
        })
        .finally(() => (this.loadingListMerk = false));
    },
    getListType(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        name: "ASSET_CATEGORY_TYPE",
      };
      if (valueSearch) params.search = `value~*${valueSearch}*`;
      this.loadingListType = true;
      masterServices
        .listMaster(params)
        .then(response => {
          this.dataListType = response;
        })
        .finally(() => (this.loadingListType = false));
    },
    handleBook(value) {
      this.bookId = value;
    },
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loadingBranch = false));
    },
    getListWarehouse(valueSearch, id) {
      if (id) {
        const params = {
          page: 0,
          limit: 10,
          search: `branchWarehouse.secureId~${id}`,
        } as RequestQueryParamsModel;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationWarehouse = true;
        logisticServices
          .listWarehouse(params, "")
          .then(data => {
            this.dataListLocationWarehouse = data.data;
          })
          .finally(() => (this.loadingLocationWarehouse = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose Location Branch first to get list warehouse",
        });
      }
    },
    getListLocationRack(valueSearch, id) {
      if (id) {
        const params = {
          page: 0,
          limit: 10,
          search: `warehouse.secureId~${id}`,
        } as RequestQueryParamsModel;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationBranch = true;
        logisticServices
          .listWarehouseLocation(params, "")
          .then(data => {
            this.dataListLocationRack = data.data;
          })
          .finally(() => (this.loadingLocationBranch = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose Location Warehouse first to get list rack",
        });
      }
    },
    handleOkQr() {
      const params: RequestQueryParamsModel = {
        unitCodes: this.valueQr.join(","),
      };
      assetsServices.getPrintQr(params).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJs(url);
          this.visibleQr = false;
        }
      });
    },
    handleClickQr(params) {
      const { value } = params;
      this.visibleQr = true;
      this.valueQr = [value];
    },
    getListAssetStatus(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_STATUS`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListStatus = data;
        })
        .finally(() => (this.loadingStatus = false));
    },
    getListPurchaseStatus(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_PURCHASE_STATUS`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingPurchaseStatus = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListPurchaseStatus = data;
        })
        .finally(() => (this.loadingPurchaseStatus = false));
    },
    getListRetiredType(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
        name: `ASSET_RETIREMENT_TYPE`,
      } as RequestQueryParamsModel;

      if (valueSearch) params.search = `value~*${valueSearch}*`;

      this.loadingRetiredType = true;
      masterServices
        .listMaster(params)
        .then(data => {
          this.dataListRetiredType = data;
        })
        .finally(() => (this.loadingRetiredType = false));
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      res.csfNumber = undefined;
      res.partNumber = undefined;
      params += this.$store.state.authStore.authData.companyName;
      params += `,${res.assetNumber || "ALL"}`;
      params += this.checkValue(`,${res["equipment"]}`);
      params += this.checkValue(`,${res["merk"]}`);
      params += this.checkValue(`,${res["type"]}`);
      params += this.checkValue(
        `,${
          this.dataBranch.find(dataFind => dataFind.id === res["branch"])?.name
        }`
      );
      params += this.checkValue(
        `,${
          this.dataListLocationWarehouse.find(
            dataFind => dataFind.id === res["warehouse"]
          )?.name
        }`
      );
      params += this.checkValue(
        `,${
          this.dataListLocationRack.find(
            dataFind => dataFind.id === res["rack"]
          )?.name
        }`
      );
      params += this.checkValue(
        `,${
          this.dataListBook.find(dataFind => dataFind.id === res["book"])?.name
        }`
      );
      params += this.checkValue(`,${res["periode"]}`);
      params += res.status ? "," + res.status.join(";") : ",ALL";
      params += this.checkValue(`,${res["purchaseStatus"]}`);
      params += this.state.time
        ? `,${moment(this.state.time).get("year")}`
        : ",ALL";
      return params;
    },
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;
        if (this.bookId) {
          const params: RequestQueryParamsModel = {
            limit: this.limit,
            page: this.page - 1,
            rentToRent: res.poRentToRent,
          };
          params.search = this.buildSearchQuery(res);
          if (res.periode) {
            params.periodMonth = res.periode;
          }
          if (res.retiredType) {
            params.retirementType = res.retiredType;
          }
          if (this.state.time) {
            params.periodYear = moment(this.state.time).format("YYYY");
          }
          params.params = this.checkParams(res);
          this.loadingDownload = true;
          assetsServices
            .getDownloadReportAsset(params, this.bookId)
            .then(data => {
              if (data) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement("a");
                link.href = url;
                const prefixFilename = moment().format(DATE_TIMESTAMP);
                link.setAttribute(
                  "download",
                  `${prefixFilename}-reports_list_of_assets.xlsx`
                ); //or any other extension
                document.body.appendChild(link);
                link.click();
              }
            })
            .finally(() => (this.loadingDownload = false));
        } else {
          this.$notification.error({
            description: "Select Book First Before Download",
            message: "Error",
          });
        }
      });
    },
    handleOpenChange(status) {
      this.state.isOpen = status;
    },
    handleChangePanel(e) {
      if (e === null) {
        this.state.time = null;
      }
    },
    handlePanel(value, _mode) {
      this.state.time = value;
      this.state.isOpen = false;
    },
    getListCalendar(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `month~*${valueSearch}*`;
      this.loadingCalendar = true;
      assetsServices
        .listCalendar(params)
        .then(data => {
          let tempData = data.data.filter(dataFilter => {
            return !dataFilter.month.includes("Adj");
          });
          this.dataCalendar = tempData;
        })
        .finally(() => (this.loadingCalendar = false));
    },
    getListAssetBook(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `name~*${valueSearch}*`;
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    responsePageSizeChange(_, size: number) {
      this.limit = size;
      this.page = 1;
      this.submitForm();
    },
    responseCurrentPageChange(page: number) {
      this.page = page;
      this.submitForm();
    },
    buildSearchQuery(res): string {
      const queries: string[] = [];
      let searchByCategory = "";

      if (res.equipment && res.merk && res.type) {
        searchByCategory = `categoryId~*${res.equipment}.${res.merk}.${res.type}*`;
      } else if (res.equipment && res.merk) {
        searchByCategory = `categoryId~*${res.equipment}.${res.merk}*`;
      } else if (res.equipment) {
        searchByCategory = `catFirst~${res.equipment}`;
      } else if (res.merk) {
        searchByCategory = `catSecond~${res.merk}`;
      } else if (res.type) {
        searchByCategory = `catThird~${res.type}`;
      }

      if (searchByCategory) {
        queries.push(searchByCategory);
      }

      if (res.assetNumber) {
        queries.push(
          new SearchBuilder().push(["assetNo", res.assetNumber]).build()
        );
      }
      if (res.status && res.status.length) {
        const statuses = res.status
          .map(e => `status~${e}`)
          .join(new SearchBuilder().OR);
        queries.unshift(statuses);
      }
      if (res.book) {
        queries.push(
          new SearchBuilder().push(["assetBookId", res.book]).build()
        );
      }
      if (res.purchaseStatus) {
        queries.push(
          new SearchBuilder()
            .push(["purchaseStatus", res.purchaseStatus])
            .build()
        );
      }
      if (res.unitCode) {
        queries.push(
          new SearchBuilder()
            .push(["unitCode", res.unitCode], { like: "both" })
            .build()
        );
      }
      if (res.serialNumber) {
        queries.push(
          new SearchBuilder()
            .push(["serialNumber", res.serialNumber], { like: "both" })
            .build()
        );
      }
      if (res.branch) {
        queries.push(
          new SearchBuilder().push(["branchId", res.branch]).build()
        );
      }
      if (res.warehouse) {
        queries.push(
          new SearchBuilder().push(["warehouseId", res.warehouse]).build()
        );
      }

      return queries.join("_AND_");
    },
    submitForm(finder = false) {
      this.form.validateFields((err, res) => {
        if (err) return;
        if (finder) this.page = 1;
        const params: RequestQueryParamsModel = {
          limit: this.limit,
          page: this.page - 1,
          rentToRent: res.poRentToRent,
        };
        if (res.periode) {
          params.periodMonth = res.periode;
        }
        if (res.retiredType) {
          params.retirementType = res.retiredType;
        }
        if (this.state.time) {
          params.periodYear = moment(this.state.time).format("YYYY");
        }
        params.search = this.buildSearchQuery(res);

        this.loading = true;
        assetsServices
          .getReportAsset(params, res.book)
          .then(response => {
            this.totalElements = response.totalElements;
            this.params = params;
            this.bookId = res.book;
            this.dataSource = response.data.map<Row>((item, i) => ({
              ...item,
              acquisitionDate: item.acquisitionDate
                ? moment(item.acquisitionDate).format("DD MMM yyyy")
                : "",
              assetCategoryId: item.assetCategory.id
                .split(".")
                .map(dataMapString => {
                  return dataMapString.replace(/\b[a-z](?=[a-z]{2})/g, e =>
                    e.toUpperCase()
                  );
                })
                .join("."),
              qrCode: item.unitCode as string,
              key: setNumbering(params.page || 0, params.limit || 0, i) - 1,
              no: setNumbering(params.page || 0, params.limit || 0, i),
            }));
            this.visible = false;
          })
          .finally(() => (this.loading = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "reportListOfAssets" });
  },
  created() {
    this.getListAssetBook("");
    this.getListCalendar("");
    this.getListAssetStatus("");
    this.getListPurchaseStatus("");
    this.getListRetiredType("");
    this.getBranch("");
    this.getListEquipment("");
    this.getListMerk("");
    this.getListType("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
