




import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      userPrivilege: "authStore/GET_USER_PRIVILEGE",
    }),
  },
})
export default class SelectUploadDataFeature extends Vue {
  userPrivilege!: string[];

  @Prop({ required: false, default: "", type: String })
  value!: string;

  availableOptions = [
    {
      key: 0,
      label: this.$t("lbl_invoice_ar").toString(),
      value: "INVOICE_AR",
      requiredPrivilege: "invoice-ar:upload",
    },
    {
      key: 1,
      label: this.$t("lbl_cash_in_out").toString(),
      value: "CASH_IN_OUT",
      requiredPrivilege: "cash-in-out:upload",
    },
    {
      key: 2,
      label: this.$t("lbl_internal_contract").toString(),
      value: "INTERNAL_CONTRACT",
      requiredPrivilege: "internal-contract:upload",
    },
    {
      key: 3,
      label: this.$t("lbl_trucking_settlement").toString(),
      value: "TRUCKING_SETTLEMENT",
      requiredPrivilege: "invoice-ar:upload",
    },
    {
      key: 4,
      label: this.$t("lbl_tax_invoice_serial_number").toString(),
      value: "TAX_INVOICE_SERIAL_NUMBER",
      requiredPrivilege: "invoice-ar:upload",
    },
  ];

  options: Array<Option> = [];

  mounted() {
    this.availableOptions.forEach(option => {
      if (this.userPrivilege.includes(option.requiredPrivilege)) {
        this.options.push({
          key: option.key,
          label: option.label,
          value: option.value,
        });
      }
    });
  }

  onChange(e: string): void {
    this.$emit("input", e);
  }
}
